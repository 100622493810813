var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-tab-navigation c-tab-navigation--new"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center"},_vm._l((_vm.navigationTabs),function(tab,index){return _c('button',{key:index,class:[`c-tab-navigation__item`, {
                'is-disabled': tab.isDisabled,
                'is-active-link': tab.path === _vm.activeTab,
                'is-valid': tab.isValid && !_vm.isOnCourseEditPage,
                'is-default': _vm.isOnCourseEditPage,
                'has-errors': tab.hasErrors
            }, `qa-navigation-tab-${tab.path}`],attrs:{"disabled":!_vm.disableTab(tab) && !_vm.isOnCourseEditPage || tab.isDisabled},on:{"click":function($event){return _vm.setTab(tab)}}},[_c('span',{staticClass:"c-tab-navigation__item-title"},[_vm._v(_vm._s(tab.title))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }