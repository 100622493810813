<template>
    <div class="c-tab-navigation c-tab-navigation--new">
        <div class="d-flex justify-content-start align-items-center">
            <button
                v-for="(tab, index) in navigationTabs"
                :key="index"
                :class="[`c-tab-navigation__item`, {
                    'is-disabled': tab.isDisabled,
                    'is-active-link': tab.path === activeTab,
                    'is-valid': tab.isValid && !isOnCourseEditPage,
                    'is-default': isOnCourseEditPage,
                    'has-errors': tab.hasErrors
                }, `qa-navigation-tab-${tab.path}`]" 
                :disabled="!disableTab(tab) && !isOnCourseEditPage || tab.isDisabled"
                @click="setTab(tab)"
            >
                <span class="c-tab-navigation__item-title">{{ tab.title }}</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TabNavigation', 
    props: {
        activeTab: {
            type: String,
            default: null
        },
        routeParamsId: {
            type: String,
            default: null
        },
        pageName: {
            type: String, 
            default: null
        },
        navigationTabs: {
            type: Array, 
            default: null
        }
    },
    data() {
        return {
            tabNavigationCounter: 1
        }
    },
    computed: {
        isOnCourseCreatePage() {
            return this.$route.name === 'course-create'
        },
        isOnCourseEditPage() {
            return this.$route.name === 'course-details'
        }
    }, 
    beforeDestroy() {
        localStorage.removeItem("setPorftolioBackRoute")  
    },
    methods: { 
        setTab(tabTo) {
            const lol = this.navigationTabs.find(el => el.path === this.activeTab)
            
            this.$bus.emit('triggerTabNavClick', {tabTo, tabFrom: lol})
        },
        disableTab(tab) { 
            const lastValidTabIndex = this.navigationTabs.findLastIndex(naviagationTab => naviagationTab.isValid)
            const nextTabAfterValidTab = lastValidTabIndex >= 0 ? this.navigationTabs[lastValidTabIndex + 1] : null

            const tabIsDisabled = this.activeTab === tab.path || tab.isValid || nextTabAfterValidTab === tab

            if (!tabIsDisabled && !this.isOnCourseEditPage) {
                tab.isDisabled = true
            } else {
                tab.isDisabled = false
            }

            return tabIsDisabled
        }
    }
}
</script>
 


